import React from "react";

const TermsAndConditions = () => {
  return (
    <div className="bg-white min-h-screen px-4 sm:px-6 md:px-10">
      <div className="transition-all duration-400 min-h-[450px] w-full max-w-[805px] py-[105px] mx-auto">
        <h1 className="text-[24px] text-[#000] font-[500] font-custom break-words mb-5">
          Terms and Conditions
        </h1>

        <p className="text-[#55595c] font-[400] text-[14px] leading-relaxed">
          Welcome to Automoviecreator.com. By accessing or using our platform,
          you agree to be bound by these Terms and Conditions ("Terms") and our
          Privacy Policy. If you do not agree, you must cease using the platform
          immediately.
        </p>

        <h2 className="text-[20px] text-[#000] font-[500] font-custom break-words mt-8 mb-2">
          1. Eligibility
        </h2>
        <p className="text-[#55595c] font-[400] text-[14px] leading-relaxed">
          You must be at least 18 years old to use our services. By using the
          site, you confirm that you meet these eligibility requirements.
        </p>

        <h2 className="text-[20px] text-[#000] font-[500] font-custom break-words mt-8 mb-2">
          2. License to Use
        </h2>
        <p className="text-[#55595c] font-[400] text-[14px] leading-relaxed">
          We grant you a limited, non-exclusive, non-transferable license to
          access and use our platform solely for your personal, non-commercial
          purposes, subject to compliance with these Terms.
        </p>

        <h2 className="text-[20px] text-[#000] font-[500] font-custom break-words mt-8 mb-2">
          3. Account Responsibilities
        </h2>
        <p className="text-[#55595c] font-[400] text-[14px] leading-relaxed">
          You are responsible for maintaining the confidentiality of your
          account credentials. You must immediately notify us of any
          unauthorized access to your account.
        </p>

        <h2 className="text-[20px] text-[#000] font-[500] font-custom break-words mt-8 mb-2">
          4. Prohibited Activities
        </h2>
        <p className="text-[#55595c] font-[400] text-[14px] leading-relaxed">
          You agree not to use the platform for any illegal or unauthorized
          purpose, including reverse engineering, data extraction, or any
          activity that disrupts or damages the platform’s functionality.
        </p>

        <h2 className="text-[20px] text-[#000] font-[500] font-custom break-words mt-8 mb-2">
          5. Content Ownership and Intellectual Property
        </h2>
        <p className="text-[#55595c] font-[400] text-[14px] leading-relaxed">
          All intellectual property on the platform, including software,
          trademarks, and content, is owned by or licensed to
          Automoviecreator.com. Users may not reproduce, distribute, or use any
          content without permission.
        </p>

        <h2 className="text-[20px] text-[#000] font-[500] font-custom break-words mt-8 mb-2">
          6. Fees and Payments
        </h2>
        <p className="text-[#55595c] font-[400] text-[14px] leading-relaxed">
          Certain features may be subject to fees. By using paid services, you
          agree to pay applicable charges and abide by our payment policies.
        </p>

        <h2 className="text-[20px] text-[#000] font-[500] font-custom break-words mt-8 mb-2">
          7. User-Generated Content
        </h2>
        <p className="text-[#55595c] font-[400] text-[14px] leading-relaxed">
          You retain ownership of content you upload, but grant us a worldwide,
          royalty-free license to display, modify, and use your content on our
          platform as necessary.
        </p>

        <h2 className="text-[20px] text-[#000] font-[500] font-custom break-words mt-8 mb-2">
          8. Disclaimer of Warranties
        </h2>
        <p className="text-[#55595c] font-[400] text-[14px] leading-relaxed">
          The platform and its contents are provided “as-is.” We make no
          warranties regarding functionality, accuracy, or availability. We
          disclaim all liability for errors, outages, or issues arising from
          use.
        </p>

        <h2 className="text-[20px] text-[#000] font-[500] font-custom break-words mt-8 mb-2">
          9. Limitation of Liability
        </h2>
        <p className="text-[#55595c] font-[400] text-[14px] leading-relaxed">
          We are not liable for any damages resulting from your use of the
          platform, including data loss or system downtime.
        </p>

        <h2 className="text-[20px] text-[#000] font-[500] font-custom break-words mt-8 mb-2">
          10. Indemnification
        </h2>
        <p className="text-[#55595c] font-[400] text-[14px] leading-relaxed">
          You agree to indemnify and hold Automoviecreator.com harmless from
          any claims, losses, or damages resulting from your breach of these
          Terms.
        </p>

        <h2 className="text-[20px] text-[#000] font-[500] font-custom break-words mt-8 mb-2">
          11. Privacy Policy
        </h2>
        <p className="text-[#55595c] font-[400] text-[14px] leading-relaxed">
          We value your privacy. Please refer to our Privacy Policy for
          information on data collection and usage.
        </p>

        <h2 className="text-[20px] text-[#000] font-[500] font-custom break-words mt-8 mb-2">
          12. Modifications to the Terms
        </h2>
        <p className="text-[#55595c] font-[400] text-[14px] leading-relaxed">
          We reserve the right to update or modify these Terms at any time, with
          notice. Continued use of the platform signifies your acceptance of any
          changes.
        </p>

        <h2 className="text-[20px] text-[#000] font-[500] font-custom break-words mt-8 mb-2">
          13. Termination
        </h2>
        <p className="text-[#55595c] font-[400] text-[14px] leading-relaxed">
          We may suspend or terminate your account if you violate these Terms.
          Upon termination, your access to the platform and data will be
          restricted.
        </p>

        <h2 className="text-[20px] text-[#000] font-[500] font-custom break-words mt-8 mb-2">
          14. Governing Law
        </h2>
        <p className="text-[#55595c] font-[400] text-[14px] leading-relaxed">
          These Terms are governed by the laws of [Company Location]. Any
          disputes will be resolved under the jurisdiction of [specific region].
        </p>

        <h2 className="text-[20px] text-[#000] font-[500] font-custom break-words mt-8 mb-2">
          15. Contact Us
        </h2>
        <p className="text-[#55595c] font-[400] text-[14px] leading-relaxed mb-4">
          For questions about these Terms, please contact us at
          info@automoviecreator.com.
        </p>

        <p className="text-[#000] font-[500] text-[14px] leading-relaxed mb-8">
          By using Automoviecreator.com, you acknowledge that you have read,
          understood, and agreed to these Terms and Conditions.
        </p>
      </div>
    </div>
  );
};

export default TermsAndConditions;
