import { FaFacebookF } from "react-icons/fa";
import { FaYoutube } from "react-icons/fa";
import { FaInstagramSquare } from "react-icons/fa";
import { GiDoctorFace } from "react-icons/gi";
import { Link } from "react-router-dom";

const Footer = () => {
  return (
<div className="bg-[#16181e] text-white">
  <div className="max-w-6xl mx-auto px-5 py-10">
    <div className="grid grid-cols-1 md:grid-cols-3 gap-6 text-center md:text-left">
      {/* Logo and Title */}
      <Link to="/" className="flex flex-col md:flex-row justify-center md:justify-start items-center gap-3">
        <div className="text-accent text-3xl">
          <GiDoctorFace />
        </div>
        <h1 className="text-2xl font-bold text-accent">AutoMovieCreator</h1>
      </Link>

      {/* Links */}
      <div className="flex flex-col md:flex-row justify-center items-center gap-3 font-medium text-md">
        <Link to="/terms-and-conditions" className="text-accent px-2 py-1 transition-colors duration-300 hover:text-violet-400 hover:underline">
          Terms & Conditions
        </Link>
        <Link to="/privacy-policy" className="text-accent px-2 py-1 transition-colors duration-300 hover:text-violet-400 hover:underline">
          Privacy Policy
        </Link>
        <Link to="/support" className="text-accent px-2 py-1 transition-colors duration-300 hover:text-violet-400 hover:underline">
          Contact Us
        </Link>
      </div>

      {/* Social Icons */}
      <div className="flex justify-center md:justify-end items-center gap-5 text-3xl">
        <a href="https://facebook.com" target="_blank" rel="noopener noreferrer" className="text-accent hover:text-[#1877F2] transition duration-300">
          <FaFacebookF />
        </a>
        <a href="https://youtube.com" target="_blank" rel="noopener noreferrer" className="text-accent hover:text-[#FF0000] transition duration-300">
          <FaYoutube />
        </a>
        <a href="https://instagram.com" target="_blank" rel="noopener noreferrer" className="text-accent hover:text-[#C13584] transition duration-300">
          <FaInstagramSquare />
        </a>
      </div>
    </div>

    {/* Bottom Line */}
    <div className="text-center text-[14px] text-[#bcc3d7] mt-8">
      <p>© {new Date().getFullYear()} AutoMovieCreator. All rights reserved.</p>
    </div>
  </div>
</div>


  );
};

export default Footer;
