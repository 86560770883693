import { useLocation, useNavigate, Link } from "react-router-dom";
import { useState, useEffect,useContext } from "react";
import axios from "axios";
import { serverbaseURL } from "../constant/index";
import { AuthContext } from "../provider/AuthProvider";
import {ArrowLeft} from "lucide-react";

const AdminDashboard = () => {
  const location = useLocation();
  const { user, userPlan, setLoading } = useContext(AuthContext);
  const { item } = location.state || {};
  const [googleId, setGoogleId] = useState("");
  const [taskId, setTaskId] = useState("");
  const navigate = useNavigate();
  const useruid = user?.uid;
  const [connectedChannels, setConnectedChannels] = useState([]);
  const [selectedChannel, setSelectedChannel] = useState('');
  const [videoTitle, setVideoTitle] = useState('');
  const [videoDescription, setVideoDescription] = useState('');

  useEffect(() => {
    const fetchConnectedChannels = async () => {
      try {
        const response = await axios.get(`${serverbaseURL}get_connected_channels`, {
          params: { uid: useruid, email: user.email }
        });
        setConnectedChannels(response.data.connectedChannels || []);
        if (response.data.connectedChannels?.length > 0) {
          setSelectedChannel(response.data.connectedChannels[0].channelId);
        }
      } catch (error) {
        console.error('Error fetching connected channels:', error);
      }
    };

    if (useruid) {
      fetchConnectedChannels();
    }
  }, [useruid, user.email]);

  const handlePostToYoutube = async (item) => {
    try {
      console.log('handlePostToYoutube hit');
      console.log(useruid);
      console.log("hi");
      console.log(item);
      const response = await axios.post(`${serverbaseURL}post_to_youtube`, {
        uid: useruid, 
        itemToPost: {
          ...item,
          title: videoTitle,
          description: videoDescription
        },
        channelId: selectedChannel
      });

      console.log('video posting response', response)
    } catch (error) {
      console.log('video posting response error', error)
    }
  }
  const handleConnectYoutube = (item) => {
    sessionStorage.setItem("taskId", item._id);
    window.location.href = `${serverbaseURL}connect_youtube?email=${user.email}&uid=${useruid}`;
  };

  const handlePostToFacebook = async (item) => {
    try {
      console.log('handlePostToFacebook hit');
      console.log(useruid);
      console.log(item);
      const response = await axios.post(`${serverbaseURL}post_to_page`, {
        uid: useruid,
        itemToPost: item
      });

      console.log('Facebook posting response', response);
    } catch (error) {
      console.log('Facebook posting response error', error);
    }
  };

  const handleConnectFacebook = () => {
    sessionStorage.setItem("taskId", item._id);
    window.location.href = `${serverbaseURL}auth/facebook`;
  };

  const handlePostToInstagram = async (item) => {
    try {
      console.log('handlePostToInstagram hit');
      console.log(useruid);
      console.log(item);
      const response = await axios.post(`${serverbaseURL}post_to_instagram`, {
        uid: useruid,
        itemToPost: item
      });

      console.log('Instagram posting response', response);
    } catch (error) {
      console.log('Instagram posting response error', error);
    }
  };

  const handleConnectInstagram = () => {
    sessionStorage.setItem("taskId", item._id);
    window.location.href = `${serverbaseURL}connect_instagram`;
  };

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);

    const updateGoogleIdInDB = async () => {
      // Extracting googleId from URL
      const params = new URLSearchParams(window.location.search);
      const googleIdFromUrl = params.get("googleId");
      if (googleIdFromUrl) {
        setGoogleId(googleIdFromUrl);
        window.history.replaceState(null, null, window.location.pathname);
      }

      const taskIdFromStorage = sessionStorage.getItem("taskId");
      if (taskIdFromStorage) {
        setTaskId(taskIdFromStorage);
      }

      if (googleIdFromUrl && taskIdFromStorage) {
        try {
          const response = await axios.patch(
            `${import.meta.env.VITE_BACKEND
            }/googleId?taskId=${taskIdFromStorage}`,
            {
              googleId: googleIdFromUrl,
            }
          );
        } catch (error) {
          console.error("Error making PATCH request:", error);
        }
      }
    };
    updateGoogleIdInDB();
  }, []);
//   useEffect(() => {
//     const params = new URLSearchParams(window.location.search);
//     const googleIdFromUrl = params.get("googleId");
//     if (googleIdFromUrl) {
//       setGoogleId(googleIdFromUrl);
//       window.history.replaceState(null, null, window.location.pathname);
//     }
//   }, []);

  return (
    <div className="min-h-screen w-full pt-4 bg-gradient-to-b from-white via-[#f1f5f9] to-[#e2e8f0]">
    <div className=" ml-10">
        <Link
          to="/dashboard"
          className="flex items-center pt-7 text-indigo-600 hover:text-indigo-700 transition duration-300 ease-in-out"
        >
          <ArrowLeft className="mr-2" size={20} />
          <span className="text-lg font-bold">Back to Dashboard</span>
        </Link>
      </div>
      <div className="max-w-6xl mx-auto p-8">
        <h1 className="text-3xl font-bold text-black mb-6">Admin Dashboard</h1>
        <div className="space-y-6">
          <div className="bg-white shadow-lg rounded-lg p-6 transition-transform transform hover:scale-105 duration-200">
            <h2 className="text-xl font-semibold mb-4">YouTube Posting</h2>
            {connectedChannels.length > 0 ? (
              <>
                <div className="mb-4">
                  <label htmlFor="channelSelect" className="block text-sm font-medium text-gray-700 mb-2">
                    Select Channel
                  </label>
                  <select
                    id="channelSelect"
                    value={selectedChannel}
                    onChange={(e) => setSelectedChannel(e.target.value)}
                    className="w-full p-2 border rounded-md"
                  >
                    {connectedChannels.map((channel) => (
                      <option key={channel.channelId} value={channel.channelId}>
                        {channel.channelName}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="mb-4">
                  <label htmlFor="videoTitle" className="block text-sm font-medium text-gray-700 mb-2">
                    Video Title
                  </label>
                  <input
                    id="videoTitle"
                    type="text"
                    value={videoTitle}
                    onChange={(e) => setVideoTitle(e.target.value)}
                    className="w-full p-2 border rounded-md"
                  />
                </div>
                <div className="mb-4">
                  <label htmlFor="videoDescription" className="block text-sm font-medium text-gray-700 mb-2">
                    Video Description
                  </label>
                  <textarea
                    id="videoDescription"
                    value={videoDescription}
                    onChange={(e) => setVideoDescription(e.target.value)}
                    className="w-full p-2 border rounded-md"
                  />
                </div>
                <p className="mb-4">You are about to post the following item:</p>
                <div className="border p-4 rounded-lg mb-4">
                  <h3 className="font-bold">{item?.topic || "N/A"}</h3>
                  <p>Language: {item?.language || "English"}</p>
                  <p>Generations: {item?.generations || 1}</p>
                </div>
                <button
                  className="bg-gradient-to-r from-[#805af5] to-blue-600 text-white py-2 px-4 rounded-md text-lg font-semibold hover:shadow-lg transition duration-200 mr-4"
                  onClick={() => handlePostToYoutube(item)}
                >
                  Post to YouTube
                </button>
              </>
            ) : (
              <p className="text-gray-600 mb-4">No channels connected yet. Connect your YouTube channel to start posting.</p>
            )}
            <button
              className="bg-gradient-to-r from-[#805af5] to-blue-600 text-white py-2 px-4 rounded-md text-lg font-semibold hover:shadow-lg transition duration-200"
              onClick={handleConnectYoutube}
            >
              Connect YouTube
            </button>
          </div>
          <div className="bg-white shadow-lg rounded-lg p-6 transition-transform transform hover:scale-105 duration-200">
            <h2 className="text-xl font-semibold mb-4">Facebook Posting</h2>
            <p className="mb-4">You are about to post the following item:</p>
            <div className="border p-4 rounded-lg mb-4">
              <h3 className="font-bold">{item?.topic || "N/A"}</h3>
              <p>Language: {item?.language || "English"}</p>
              <p>Generations: {item?.generations || 1}</p>
            </div>
            <button
              className="bg-gradient-to-r from-[#3b5998] to-[#8b9dc3] text-white py-2 px-4 rounded-md text-lg font-semibold hover:shadow-lg transition duration-200 mr-4"
              onClick={() => handlePostToFacebook(item)}
            >
              Post to Facebook
            </button>
            <button
              className="bg-gradient-to-r from-[#3b5998] to-[#8b9dc3] text-white py-2 px-4 rounded-md text-lg font-semibold hover:shadow-lg transition duration-200"
              onClick={handleConnectFacebook}
            >
              Connect Facebook
            </button>
          </div>
          <div className="bg-white shadow-lg rounded-lg p-6 transition-transform transform hover:scale-105 duration-200">
            <h2 className="text-xl font-semibold mb-4">Instagram Posting</h2>
            <p className="mb-4">You are about to post the following item:</p>
            <div className="border p-4 rounded-lg mb-4">
              <h3 className="font-bold">{item?.topic || "N/A"}</h3>
              <p>Language: {item?.language || "English"}</p>
              <p>Generations: {item?.generations || 1}</p>
            </div>
            <button
              className="bg-gradient-to-r from-[#C13584] to-[#E1306C] text-white py-2 px-4 rounded-md text-lg font-semibold hover:shadow-lg transition duration-200 mr-4"
              onClick={() => handlePostToInstagram(item)}
            >
              Post to Instagram
            </button>
            <button
              className="bg-gradient-to-r from-[#C13584] to-[#E1306C] text-white py-2 px-4 rounded-md text-lg font-semibold hover:shadow-lg transition duration-200"
              onClick={handleConnectInstagram}
            >
              Connect Instagram
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AdminDashboard;