import React, { useState, useContext } from "react";
import { useNavigate } from "react-router-dom";
import axios from 'axios';
import { Loader2 } from "lucide-react";
import {serverbaseURL} from "../constant/index.jsx";
import { AuthContext } from "../provider/AuthProvider";
import { motion } from 'framer-motion';

const Templates = () => {

  const hardcodedTemplates = [
    {
      id: 1,
      title: "Gummy Bear Showdown",
      name: "The Gummy Bear Showdown",
      description: "Dwayne The Rock Johnson and Arnold Schwarzenegger face a hilarious battle with giant gummy bears that come to life, causing playful chaos in a sunny suburban playground.",
      type: "Custom",
      image: "https://res.cloudinary.com/dyzcpcibf/image/upload/v1731090461/generated_test_images/trwf48fpuarcluodpybt.png",
      previewVideo: "https://www.w3schools.com/html/mov_bbb.mp4",
      height: 1280,
      width: 1280,
      style: 'RENDER_3D',
      styleUUID: 'debdf72a-91a4-467b-bf61-cc02bdeb69c6',
      music: "https://res.cloudinary.com/dyzcpcibf/video/upload/v1732371604/music/jr7cwyuosslbsyjsgtse.mp3",
      musicDuration: 11.099563,
      storyPrompt: `None`,
      themeTemplate: `i want you create a another story with dwyne johnason and divide that story into 9 scenes and then create text prompt for each scene in details to generate images in leonardo. Treat each prompt as fresh prompt while keeping the clothes and background reference in each prompt same. strictly, In each prompt out of 9, mention characters clothes in text and color in text prompt so it is not missed in any prompt and background. directly give the image prompt nothing else. for reference this is the story - In a whimsical playground sandbox, Dwayne Johnson, clad in a gray tank top adorned with a tribal tattoo, sits cross-legged, fully engrossed in a game of alphabet blocks. Beside him is an amusingly unique character: a toddler's body dressed in a blue-striped polo shirt, but with the head of Arnold Schwarzenegger, complete with a gray beard, glasses, and an enthusiastic expression. Their playful interaction is surrounded by a scatter of colorful ABC blocks, radiating joy.

      The vibrant scene is set against a sunny backdrop featuring a yellow and red playground structure with slides, lush green trees, and an American flag fluttering in the distance. The lighting is bright and cheerful, casting soft shadows in the sandbox, creating a heartwarming atmosphere.

      As the story unfolds, the playful spirit continues in subsequent scenes. In one instance, Arnold's toddler body is juxtaposed with his hyper-realistic facial features, showcasing an exaggerated intense expression while shouting with clenched fists in a bustling playground. The contrasting styles enhance the absurdity of the moment.

      Further along, Dwayne's shocked face becomes a focal point in a vibrant playground filled with children, as he reacts to a surreal swarm of menacing gummy bears that appear to chase him, setting a humorous yet tension-filled scene.

      The climax builds with Dwayne engaged in a comical martial arts battle against a giant red gummy bear, showcasing his determination. In a humorous twist, he grapples with a giant turquoise gummy bear in a suburban front yard, surrounded by an audience of smaller gummy bears watching intently, their expressions adding to the whimsical nature of the unfolding drama.

      The narrative weaves through absurd and surreal moments, emphasizing the blend of humor and charm in the delightful playground setting, creating a story that captures the imagination and tickles the funny bone.

      Scene 1: the rock and arnold A whimsical digital art piece set in a sunny playground sandbox: Dwayne johnason (wearing a gray tank top with tribal tattoo on his arm) sits cross-legged playing with alphabet blocks alongside a uniquely proportioned character - a 1-year-old's body wearing a blue-striped polo shirt but with Arnold Schwarzenegger's head with gray beard and hair with glasses and an enthusiastic expression. Both characters are joyfully engaged with colorful ABC blocks scattered around them. Background features a yellow and red playground structure with slides, green trees, and an American flag waving in the distance. The lighting is bright and cheerful, creating a heartwarming scene with soft shadows in the sandbox. The art style should be detailed 3D rendering with a slight cartoonish quality, emphasizing the playful nature of the scene. The color palette should be vibrant with emphasis on the primary colors of the alphabet blocks.

      scene 2: the rock and arnold Hyper-realistic 3D render of a surreal character: a toddler's body with Arnold Schwarzenegger's head with gray beard and hair with glasses (approximately 3 years old) with a disproportionately large head depicting intense facial expression, wearing a casual outfit consisting of a white and blue horizontal striped polo shirt, olive green shorts, and white socks with shoes. The character is posed in an exaggerated stance with clenched fists and an intense, shouting expression. The character is positioned in a playground setting with wood chips/mulch on the ground, and a yellow and red playground structure with slides in the background. Soft bokeh effect on background figures walking around. The lighting should be natural daylight with trees visible in the far background. The art style should be highly detailed 3D rendering with emphasis on the contrast between the cartoonish body proportions and the hyper-realistic facial features. The character's height should be approximately that of a toddler, around 3 feet tall.

      scene 3: Image is a digitally manipulated, hyper-realistic illustration featuring a bald man (dwayne johnson, the rock) with exaggerated facial features, including wide eyes and an open mouth, conveying a shocked expression. His skin is a medium brown tone with visible textures and pronounced veins on his neck. The background depicts a vibrant playground scene with children and adults, some wearing casual summer clothing. The playground equipment includes colorful slides and climbing structures, primarily in red, yellow, and green. The setting is outdoors, with lush green trees and a clear sky, suggesting a sunny day. The overall composition combines a humorous and surreal style, focusing on the central figure's exaggerated expression against a lively, everyday backdrop. he is the The Rock wearing the gray shirt.

      scene 4: Image is a digitally manipulated, hyper-realistic illustration featuring a bald man (dwayne johnson, the rock) with exaggerated facial features, shaved beard, including wide eyes and an open mouth, conveying a shocked expression. His skin is a medium brown tone with visible textures and pronounced veins on his neck. Behind him, a surreal scene unfolds in a playground setting: hundreds of evil red gummy bears appearing to chase/swarm in the background. The setting is outdoors, with lush green trees and a clear sky, suggesting a sunny day. The overall composition combines a humorous and surreal style, focusing on the central figure's exaggerated expression against a lively, everyday backdrop. he is the The Rock wearing the gray shirt.

      scene 5: Image is a digitally manipulated, hyper-realistic illustration featuring a bald man (dwayne johnson, the rock) in mid-kick martial arts pose wearing a white t-shirt (showing tribal tattoo on arm) and black pants, executing a high kick against a giant red gummy bear. The man's expression shows intense concentration/determination. The red gummy bear stands approximately 6 feet tall with a translucent, candy-like texture and is positioned as if in combat stance. Setting is a suburban yard with a house visible in background, surrounded by hundreds of smaller, multicolored gummy bears in various bright hues (blue, green, yellow, orange, red, purple) creating a sea of candy. The lighting is bright natural daylight filtering through trees, creating glossy highlights on the gummy bears' surfaces. Some gummy bears are shown flying through the air from the impact. The composition should capture the dynamic movement of the kick with slight motion blur. Style should be photorealistic 3D rendering with particular attention to the translucent candy texture of the bears and the natural fabric wrinkles in the clothing. Background should include a gray/white suburban house and green trees. Depth of field should keep the main action in sharp focus while maintaining the vibrant color array of background gummy bears.

      scene 6: Image is a digitally manipulated, hyper-realistic illustration featuring a bald man (dwayne johnson, the rock) gray tank top (with tribal tattoo) and dark pants is locked in battle with a giant turquoise gummy bear. The man is captured sprawled across the bear's back in a combat grapple/submission hold position, with an intense expression of effort (exaggerated screaming face, wide eyes, open mouth). The giant turquoise gummy bear is approximately 8 feet tall with a glossy, translucent candy texture, appearing to be struggling against the hold. Setting is a suburban front yard with a two-story gray house with red chimney visible in background. The scene is surrounded by hundreds of smaller multicolored gummy bears (red, green, yellow, orange) creating an audience effect, each with unique expressions watching the battle. Lighting is natural daylight with trees in background creating dynamic shadows.

      scene 7 : Create a surreal and humorous scene in a suburban neighborhood with a large, dangerous-looking bright blue bear holding a bald man (dwayne johnson, the rock) gray tank top (with tribal tattoo) and dark pants is locked in battle with a giant turquoise gummy bear in a tense bear hug. The bear has sharp, menacing teeth, wide, intense eyes, and a fierce expression, making it appear both comical and threatening. The muscular man, bald and tattooed on his shoulder, looks terrified and is dressed casually with one green Croc sandal. They are surrounded by scattered, colorful gummy bears on the ground, adding a strange contrast to the tension. The backdrop includes charming suburban houses under a cloudy sky, heightening the absurdity of the scene. the rock both hands are clearly visible and no extra body parts are there.The road is filled with 1000 gummy bears.

      scene 8: Create a surreal and humorous scene in a suburban neighborhood with a large army or swarm of big gummy bears chasing and running behind a bald man (dwayne johnson, the rock) gray tank top (with tribal tattoo, and shaved beard) and dark pants is running away from gummy bears army in fear. The muscular man, bald and tattooed on his shoulder, looks terrified and is dressed casually with one green Croc sandal. They are surrounded by scattered, colorful gummy bears on the ground in a running action pose, adding a strange contrast to the tension. The backdrop includes charming suburban houses under a cloudy sky, heightening the absurdity of the scene. the rock both hands are clearly visible and no extra body parts are there.The road is filled with 1000 gummy bears.

      scene 9: surreal, humorous scene in a suburban neighborhood featuring a large red gummy bear with sharp teeth, wide eyes, and a menacing expression, holding a knife and chasing after a terrified bald man resembling Dwayne 'The Rock' Johnson. The muscular man is dressed in a gray tank top with a tribal shoulder tattoo, dark pants, and one green Croc sandal, looking back in fear as he runs. The street is filled with colorful, scattered gummy bears, adding a strange contrast to the tense chase. The scene is set against charming suburban houses under a cloudy sky, enhancing the absurdity. The Rock's hands are clearly visible and empty, with no extra body parts present.
      Important: Strictly format the response as [[{ "story": "Just Write 'Music' here" }], [{ "prompt": "..." },...]] without any extra text or numbering.`
    },
    {
      id: 2,
      title: "Clash of Superheros",
      name: "Clash of Superheros",
      description: "Supergirl leads to a story of strength and friendship, as Supergirl trains to avenge her mentor's fall and ultimately takes down the mighty Thanos.",
      type: "Custom",
      image: "https://res.cloudinary.com/dyzcpcibf/image/upload/v1730024124/generated_test_images/dl8zt4ijyryqdxayevde.png",
      height: 1280,
      width: 720,
      style: 'RENDER_3D',
      styleUUID: 'debdf72a-91a4-467b-bf61-cc02bdeb69c6',
      music: "https://res.cloudinary.com/dyzcpcibf/video/upload/v1732344435/music/z446mar2gq6jmzobq98f.mp3",
      musicDuration: 59.099563,
      previewVideo: "https://www.w3schools.com/html/mov_bbb.mp4",
      storyPrompt: `Write a visually captivating revenge story featuring Supergirl, She-Hulk, and Thanos as the main characters. The story should be structured to inspire a series of dynamic and cinematic photo-based scenes. Follow these guidelines:
      Story Arc:
      The narrative revolves around Supergirl taking revenge on Thanos for injuring She-Hulk during an intense battle. Introduce a dramatic training montage where Supergirl grows stronger, and escalate the tension leading to a final showdown. End with a powerful resolution that highlights justice, triumph, and the bond between Supergirl and She-Hulk. Scene Structure: Divide the story into 10 key scenes, each crafted for visual storytelling. For example:
      Scene 1: She-Hulk and Supergirl sparring in a vibrant training ground. Scene 2: Thanos storming in, disrupting their peace. Scene 5: She-Hulk lying defeated as Supergirl vows revenge. Scene 10: Supergirl overpowering Thanos in an explosive battle. Character Details:
      Supergirl: Portray her as radiant and strong, with glowing confidence. Describe her youthful features, flowing blonde hair, and iconic red-and-blue Kryptonian suit. Highlight her resilience and fiery determination. She-Hulk: Depict her as a towering, muscular figure with vibrant green skin and long black hair. She wears a modern combat suit with purple accents. Show her as both a fighter and a symbol of loyalty. Thanos: Present him as an imposing villain with rugged, purple skin, clad in golden armor that gleams ominously. Emphasize his cruelty and unyielding power. Visual Details for Each Scene:
      Environment: Vibrant and dramatic settings—training grounds, battlefields, cosmic landscapes. Lighting: Use cinematic, dynamic lighting with contrasts to heighten emotions. Colors: Bold, vibrant hues for heroes; darker, ominous tones for Thanos. Action: Detailed and dramatic poses to convey motion and intensity. Storytelling Style:
      Use short, vivid sentences for high impact. Describe the emotions, expressions, and physical actions of characters in great detail. Focus on the visual aspects of each scene to make them easily translatable into high-quality imagery. End with a powerful and hopeful conclusion, where Supergirl and She-Hulk reaffirm their friendship, standing victorious amidst a stunning background of light and colors."`,
      themeTemplate: `None`
    },
    {
      id: 3,
      title: "Kitten and Dad",
      name: "Kitten and Dad",
      description: "A heartwarming story of a playful kitten and her loving dad bear as they navigate a hilarious and slimy mess. Together, they discover the strength of their bond amidst the chaos, creating a cozy and unforgettable adventure.",
      type: "Custom",
      image: "https://res.cloudinary.com/dyzcpcibf/image/upload/v1733077022/Template%20Card%20Images/rgh6wppcghpek0kysy4n.png",
      previewVideo: "https://www.w3schools.com/html/mov_bbb.mp4",
      height: 1280,
      width: 720,
      style: 'RENDER_3D',
      styleUUID: 'debdf72a-91a4-467b-bf61-cc02bdeb69c6',
      music: "https://res.cloudinary.com/dyzcpcibf/video/upload/v1732338442/music/i61n1kk3gvhd31lihfkz.mp3",
      musicDuration: 28.099563,
      storyPrompt: `Create a unique story with kitten doing something stupid and cute then creates a problem and his dad needs to solve it add drama and excitement to it with a unexpected ending (sad or happy) using boring words and only these two characters without creating any new one -
      Tabby Cat Dad - 
      Body Color: Orange-and-brown striped fur, typical of a tabby cat. Clothing: Wears a cozy blue sweater paired with beige pants. The sweater has a soft, knitted texture, and the pants are slightly loose for a relaxed, casual look. Personality: Gentle and caring, with a patient demeanor. He exudes warmth and affection, though he's occasionally flustered by his kittens antics. Despite the chaos, he maintains a sense of humor and an unmistakable bond with his little one. Role: The responsible, loving father navigating the playful chaos of parenting.
      Kitten - 
      Body Color: Light cream-colored fur, soft and fluffy, giving an innocent and adorable appearance. Clothing: Dressed in a tiny yellow onesie that fits snugly, adding to its charm. The onesie is often messy due to its energetic and curious nature. Personality: Mischievous and full of energy, with an adventurous spirit. The kitten loves exploring and finds joy in the simplest things. Its playful demeanor contrasts with its fathers composed attitude, creating a delightful dynamic. Role: The curious and cheeky child, constantly on the move, bringing laughter and chaos to the scene.`,
      themeTemplate: `None`
    },
    {
      id: 4,
      title: "Gautam Buddha",
      name: "Gautam Buddha",
      description: "A troubled king learns the power of silence and self-reflection under the guidance of a wise monk, discovering inner peace in a quiet forest.",
      type: "Custom",
      image: "https://res.cloudinary.com/dyzcpcibf/image/upload/v1733077552/Template%20Card%20Images/potnmzotqqwrnwztqnwm.png",
      previewVideo: "https://www.w3schools.com/html/mov_bbb.mp4",
      height: 1280,
      width: 720,
      style: 'BOKEH',
      styleUUID: '9fdc5e8c-4d13-49b4-9ce6-5a74cbb19177',
      music: "None",
      musicDuration: "None",
      storyPrompt: `Create a narration story under 125 words inspired by monk Gautama buddha which teaches the meaning of life or peace or other morals said by him.`,
      themeTemplate: `None`
    },
    {
      id: 5,
      title: "Watermelon Cat",
      name: "Watermelon Cat",
      description: "A cat with a watermelon head and a watermelon body, wearing a red bandana around its neck, and a pair of sunglasses on its head. It is sitting on a bench with a watermelon in its hands, looking at the camera.",
      type: "Custom",
      image: "https://res.cloudinary.com/dyzcpcibf/image/upload/v1733077353/Template%20Card%20Images/qksiuuryd59gjjsojmbr.png",
      previewVideo: "https://www.w3schools.com/html/mov_bbb.mp4",
      height: 1280,
      width: 720,
      style: 'RENDER_3D',
      styleUUID: 'debdf72a-91a4-467b-bf61-cc02bdeb69c6',
      music: "https://res.cloudinary.com/dyzcpcibf/video/upload/v1733078303/music/ltp0lg8qzbmlsvfjhfeu.mp3",
      musicDuration: 55.099563,
      storyPrompt: `Create A unique and fantasy story under 150 words with suspense and thrill and action Using three characters, do not name the characters just refer them as mother cat and kitten and villian. also the mother cat is very giant like a fantasy character. A kitten whose fur is like (light green and dark green striped)and her mother cat who is also has fur like like (light green and dark green striped) cat, strictly mention their light and dark green striped fur. And a villian is a human and make sure there is intro , suspense, reveal, action, climax, happy ending.`,
      themeTemplate: `None`
    },
    {
      id: 6,
      title: "You have Entered Creator Mode",
      name: "You have Entered Creator Mode",
      description: "Step into an exclusive realm where creativity reigns supreme. You’ve been chosen to join the Creator Mode, a space bursting with limitless possibilities, where imagination takes center stage, and every idea can come to life.",
      type: "Custom",
      image: "https://res.cloudinary.com/dyzcpcibf/image/upload/v1733077845/Template%20Card%20Images/arfoia48iks3jxn4ttif.png",
      previewVideo: "https://www.w3schools.com/html/mov_bbb.mp4",
      height: 1280,
      width: 720,
      style: 'ILLUSTRATION',
      styleUUID: '645e4195-f63d-4715-a3f2-3fb1e6eb8c70',
      music: "None",
      musicDuration: "None",
      storyPrompt: `Create a motivation narration under 125 words inspired by the this "you're here not by coincidence not by chance something much bigger than you and I has guided you to this exact moment you've entered Creator mode and if you're still watching it's because you've been chosen by them now before we go any further let's clear something up who are they is this some cryptic Force some hidden power watching your every move the the truth is deeper than that they are not an external Force some shadowy figures pulling strings they are the universe they are the energy that runs through all 
      things the collective consciousness of all that has ever existed and will ever exist and now they have chosen you click subscribe to this channel to get more profound spiritual lessons [Music] What does Creator Mode mean you're not just another person scrolling through life passively consuming information you've been activated you're now in the driver's seat of your own reality but what does Creator mode mean it means that from this moment on you're no longer bound by the rules that confine most people you're no longer a victim of circumstance tossed around by The Winds of Fate you are the AR architect of your own Universe 
      think about it how often do you feel like life is happening to you like you're being pushed in directions you didn't choose he that ends now because the moment you entered this space you crossed a threshold you've been given access to the same energy that drives the stars that moves the oceans and breathes life into this planet you have the ability to shape and bend reality itself but the question is are you ready for that kind of Are you ready responsibility the first thing you need to understand about Creator mode is that it's not a gift it's a challenge you've been chosen because you're ready for this even if you don't fully realize it yet
      you're ready to step into your power but with great power comes great responsibility the universe has been watching you testing you preparing in you for this moment and if you're feeling a sense of unease right now that's normal Because deep down you know that once you Embrace this there's no going back now let's get practical how do you actually enter Creator mode how do you start manifesting your own reality it starts with your perception most people live their entire lives seeing the world through a foggy lens they see limitation obstacles reasons why things won't work out but in Creator mode you don't see limitations you see opportunities
      every challenge becomes a stepping stone every setback becomes fuel for your growth you stop asking why is this happening to me and start asking how can I use How can I use this this you see when you're in Creator Mode Nothing is wasted every experience every emotion every moment becomes a tool for creation you stop being reactive and start being proactive you stop waiting for the universe to hand you what you want and you start taking action knowing that the universe is working with you not against you but here's the twist you're not creating in isolation you're co-creating with the universe and this is where they come back into the picture they 
      are the subtle forces the synchronicities The Whispers of intuition that guide you you've probably already had moments where you felt this where things just seemed to align perfectly where the right person the right opportunity the right idea came to you at exactly the right time that's not a coincidence that's the universe stepping in to say we're with you keep going [Music] How do I know if Im in alignment but here's the big question how do you know if you're truly in alignment with this Universal flow how do you know if you're really co-creating or if you're just forcing things to happen the answer is simple but profound you know you're in 
      alignment when things feel effortless that doesn't mean there won't be challenges it means you flow through those challenges with a sense of calm and certainty you know deep down that everything is unfolding exactly as it should there's no need to control or manipulate you trust the process because you trust yourself and this is where a lot of people struggle they want to control every detail they want to force reality to conform to their idea of how things should be but in Creator mode you don't force you flow you set your intention you take inspired action and then you let go you trust that the universe is handling the how while you focus
      on the why and the why my friend is everything why do you want to create why do you want to manifest your desires is it for personal gain is it to prove something to others or is it because you feel a deep calling to create something bigger than Is it to prove something to others yourself here's the truth the more a your Creations are with the greater good the more power you will have the universe is not interested in ego-driven Pursuits it doesn't respond to selfish desires but when you create from a place of love from a place of genuine service to others the universe will move mountains to support you because ultimately we're all part of the 
      same fabric of existence when you lift others up you lift yourself up when you contribute to the collective the collective contributes to you now let's dive deeper what does it actually feel like to be in Creator mode it feels like a state of flow where time seems to disappear where you're so immersed in the Act of Creation that everything else Fades away it feels like being fully present Fully Alive fully connected to the moment it feels like a deep sense of Peace even amidst the chaos of life because when you're in Creator mode you're not reacting to the external world you're shaping it from Youre still human within but here's the thing you're
      still human you're going to have moments of Doubt moments where you question yourself moments where you feel like you've lost your way that's part of the journey creator mode doesn't mean you're immune to challenges it means you're equipped to handle them in a whole new way it means you have the tools to navigate the ups and downs of life with Grace and wisdom it means you're no longer at the mercy of your circumstances because you've learned how to shape your circumstances from the inside out so here's my question to you are you willing to let go of your old identity the one that's been conditioned to believe in limitations and step fully into your 
      power as a Creator are you ready to embrace the unknown to trust the process to co-create with the universe in ways you've never imagined because if you're seeing this it's because they believe you're ready the only question left is do Do you now you now that you've entered Creator mode the next step is learning how to connect with the silent forces guiding you these forces are always present always working behind the scenes but most people are too distracted to notice them you've probably felt their presence before those moments of intuition of knowing without knowing a feeling like something or someone is guiding you these aren't random they're 
      The Whispers of the universe the subtle nudges that are constantly pointing you in the right direction [Music] but here's the thing the universe doesn't shout it doesn't Force its way into your Consciousness it speaks softly in the spaces between your thoughts in The Quiet Moments when you're fully present and this is where most people miss the message they're so caught up in the noise of their own minds in the chaos of their daily lives that they drown out the very guidance they're seeking so how do you tune in how do you hear The Whispers of the universe amidst the noise it starts with Stillness in Creator mode you must cultivate a practice of 
      Stillness whether it's through meditation mindful breathing or simply taking a few moments each day to be fully present when you quiet the mind you create space for the universe to speak and when you listen closely you'll start to notice the signs the synchronicity the subtle messages that are always there guiding you toward your Highest [Music] Trust Potential but here's the key you must trust what you hear too often people receive these messages but dismiss them as mere coincidences or random thoughts they doubt their intuition second guess their inner knowing but in Creator mode doubt is your biggest enemy you must learn to trust yourself to 
      trust the guidance you receive even when it doesn't make logical sense because the universe doesn't operate according to human logic it operates according to a higher intelligence one that sees the full picture now let's get practical how do you start recognizing these signs how do you differentiate between a random thought and genuine guidance it comes down to feeling when you receive true guidance from the universe it comes with a sense of calm of certainty of Peace it feels light expansive and aligned on the other hand when a thought comes from Fear doubt or ego it feels heavy constrictive and full of tension pay attention to how your body feels 
      when you receive a message your body is a powerful tool for discernment [Music] Fear but here's where it gets tricky sometimes the guidance you receive will push you out of your comfort zone it will ask you to do things that scare you things that challenge you things that require you to let go of control and this is where many people turn back they feel the fear and they assume that it's a sign to stop but in Creator mode fear is not a stop sign it's a green light it's a signal that you're about to step into something bigger than yourself something that will stretch you grow you and ultimately Elevate you so here's my next deep question for 
      you are you willing to trust the guidance you receive even when it scares you even when it doesn't make sense to your rational mind even when it requires you to let go of everything you thought you knew because this is the true test of Creator mode it's not about creating a a comfortable life it's about creating a life that's in alignment with your Highest Potential and sometimes that means walking a path that others can't see or [Music] The beauty of it understand but here's the beauty of it when you start following this guidance when you start trusting the silent forces that are always guiding you life becomes magical synchronicities become 
      the norm doors open where there were once walls opportunities appear out of nowhere and the more you trust the more the universe responds it's like a dance you take a step the universe takes a step you make a move the Universe moves with you but remember this is not a passive process in Creator mode you're an active participant in your own Evolution you're not sitting back waiting for the universe to hand you what you want want you're co-creating with it every step of the way you're listening trusting and taking inspired action and the more you do this the more you'll start to see the power you The role of faith hold now let's talk about the 
      role of faith in all of this faith is not just a religious concept it's a fundamental principle of creation faith is the belief in things unseen the the knowing that even when you can't see the full picture everything is unfolding exactly as it should in Creator mode faith is your anchor it's what keeps you grounded when things don't go according to plan when the path seems unclear when doubt Creeps in without faith you'll constantly be second guessing yourself questioning whether you're on the right track but with faith you move through life with a sense of peace and certainty knowing that the universe is always supporting you so how do you 
      cultivate faith it starts with small steps every time you trust your intuition every time you follow the guidance you receive you build your faith muscle and over time as you see the results of your trust your faith grows stronger you start to realize that the universe is always working in your favor even when it doesn't seem like it you start to see the bigger picture the intricate web of synchronicities that are constantly unfolding behind the [Music] The role of challenges scenes but here's the thing Faith doesn't mean you'll never face challenges in fact the challenges are part of the process they're designed to help you grow to help you evolve 
      to help you step into your full power as a Creator so the next time you face a CH challenge instead of seeing it as a roadblock ask yourself what is this challenge here to teach me how can I use this to become stronger wiser more aligned with my true self now that you've entered Creator mode and learn to connect with the silent forces guiding you the next step is mastering the art of manifestation manifestation is not just about thinking positive thoughts and hoping for the best it's a science a process a deliberate Act of Creation and when you're in Creator mode you have the power to manifest anything you desire if you know how to do it 
      Thoughts are energy consciously the first thing you need to understand about manifestation is that thoughts are energy every thought you think sends out a vibration into the universe and that vibration attracts experiences people and circumstances that match it frequency this is the basic principle of the Law of Attraction but here's where most people get it wrong they think that just by thinking positive thoughts they'll automatically attract positive outcomes but it's not that simple in Creator mode it's not just your thoughts that matter it's your beliefs your emotions and your actions you can think positive thoughts all day long 
      but if deep down you don't believe that what you want is possible or if you're constantly feeling fear doubt or lack you'll continue to attract experiences that match those lower vibrations this is why so many people struggle with manifestation they're focusing on the surface level without addressing the deeper energetic patterns that are running the How to manifest [Music] show so how do you manifest consciously it starts with Clarity you need to be crystal clear about what you want to create vague desires produce vague results the universe responds to specificity to intention to focus so ask yourself what do 
      I truly want not what Society tells you to want not what your ego thinks you should want but what your soul truly desires get clear on that and then align your thoughts beliefs emotions and actions with that desire the next step is to feel as if your desire has already manifested this is where most people get tripped up they think that they need to wait for their desire to show up before they can feel happy grateful or excited but in Creator mode you understand that feeling comes first you must generate the emotions of having already received what you want before it actually shows up in your reality this is the secret to manifestation when 
      you feel as if your desire is already here you're emitting the vibration that attracts it to Selfawareness you but here's the catch you can't fake it you can't just pretend to feel happy or grateful while secretly doubting that your desire will ever come the universe doesn't respond to what you say it responds to what you truly believe and feel so if you're struggling to generate those feelings it's a sign that there's some deeper inner work to be done you may need to identify and release old limiting beliefs fears or doubts that are blocking you from fully stepping into the energy of your desire this is where self-awareness comes in
      Creator mode you must become hyper aware of your inner world you must constantly monitor your thoughts emotions and beliefs and ask yourself is this in alignment with what I want to create if not you need to make a conscious shift this might mean reframing a negative thought releasing a limiting belief or simply choosing to feel gratitude in the moment even if things aren't yet Action Manifestation perfect and here's the final piece action manifestation is not just a Al process it's a physical one you must take inspired action toward your desires this doesn't mean forcing things to happen or hustling non-stop it means tuning into your intuition 
      listening to the guidance of the universe and acting on it when you take action from a place of alignment things start to move quickly doors open opportunities appear and the universe conspires to bring you exactly what you need but remember there's no rush in Creator mode you understand that everything happens in Divine timing you don't need to force or push you simply need to trust align and take inspired action and when you do this consistently you'll start to see the results of your efforts you'll start to see that you truly are the creator of your own reality so here's my final deep question for you what are you ready to create what life are 
      you ready to manifest now that you've entered Creator mode because the universe is listening the only thing left is for you to decide"`,
      themeTemplate: `None`
    },
    {
      id: 7,
      title: "Countries as Mafia's",
      name: "AI Draws Countries as Mafia's",
      description: "Experience the dark allure of international mafia bosses, with AI-crafted portraits representing countries like Italy, Japan, Russia, Mexico, and more. Each boss captures the essence of their homeland, paired with gripping narratives and vivid imagery.",
      type: "Custom",
      image: "https://res.cloudinary.com/dyzcpcibf/image/upload/v1732372464/generated_test_images/ahibqtejzfllbn28vihm.png",
      previewVideo: "https://www.w3schools.com/html/mov_bbb.mp4",
      height: 1280,
      width: 720,
      style: 'CINEMATIC',
      styleUUID: 'a5632c7c-ddbb-4e2f-ba34-8456ab3ac436',
      music: "https://res.cloudinary.com/dyzcpcibf/video/upload/v1734118640/music/ozl7ichptda4gepp7dk9.mp3",
      musicDuration: 30.099563,
      storyPrompt: `None`,
      themeTemplate: `Create a random list of 10 random counties and then generate 10 text prompt for Leonardo which shows high quality person portrait with a intense expression till the belly which represents that each country as a mafia boss.
      Important: Strictly format the response as [[{ "story": "Just Write 'Music' here" }], [{ "prompt": "..." },...]] without any extra text or numbering.`
    },
    {
      id: 8,
      title: "Animals X Superheros",
      name: "Animals X Superheros",
      description: "Unleash the power of imagination by pairing Marvel and DC superheroes with animals. Generate stunning visuals of superheroes alongside their animal counterparts and fused hybrids, blending their iconic costumes with the essence of the wild.",
      type: "Custom",
      image: "https://res.cloudinary.com/dyzcpcibf/image/upload/v1732371700/generated_test_images/mbcxrgyop2nyml9yesbi.webp",
      previewVideo: "https://www.w3schools.com/html/mov_bbb.mp4",
      height: 1280,
      width: 720,
      style: 'CINEMATIC',
      styleUUID: 'a5632c7c-ddbb-4e2f-ba34-8456ab3ac436',
      music: "https://res.cloudinary.com/dyzcpcibf/video/upload/v1732535982/music/zf6mnkityoivhaxrqivd.mp3",
      musicDuration: 45.0948,
      storyPrompt: `None`,
      themeTemplate: `Create a random list of 7 super heros  only from marvel and Dc universe and 7 animals  and pair them like this superhero + animal and then create a two text prompt for each pair first prompt to generate superhero and animal standing together and 2nd prompt the animal combined and fusioned with that superhero such that the body is of that animal and the costume is of that superhero.
      Make sure that the first prompt of each pair is highly detailed and clear such that it should not make the animal wear any costume and the animal is raw, keep the text prompt in under 100 words also add keywords like highly detailed, HD, 8K, Clear Face and etc.
      Format the response as an array of 2 arrays, the first one has one video story for 30-45 seconds narration and second array has 14 objects each having 'prompt' as key containing the respective image prompt.

      Important: Strictly format the response as [[{ "story": "Just Write 'Music' here" }], [{ "prompt": "..." },...]] without any extra text or numbering.`
    },
    {
      id: 9,
      title: "A Man Did EVERYTHING",
      name: "A Man Did EVERYTHING",
      description: "Craft heartwarming stories of self-discovery and connection, inspired by life's simple moments. Generate stunning visual scenes and narratives that explore themes of mindfulness, peace, and spiritual awakening.",
      type: "Custom",
      image: "https://res.cloudinary.com/dyzcpcibf/image/upload/v1733077292/Template%20Card%20Images/kadzwndmgg3omvvgylhi.png",
      previewVideo: "https://www.w3schools.com/html/mov_bbb.mp4",
      height: 1280,
      width: 720,
      style: 'ILLUSTRATION',
      styleUUID: '645e4195-f63d-4715-a3f2-3fb1e6eb8c70',
      music: "None",
      musicDuration: "None",
      storyPrompt: `Create a Emotional and Sad Story of Brother and Sister under 150 words that shows the strong bond between them.`,
      themeTemplate: `None`
    },
    {
      id: 10,
      title: "Power of words Motivation",
      name: "Power of words",
      description: "Witness how the magic of words can inspire, heal, and unite. Follow a captivating journey where characters learn the profound impact of their speech in a tale of transformation and resilience.",
      type: "Custom",
      image: "https://res.cloudinary.com/dyzcpcibf/image/upload/v1733079093/Template%20Card%20Images/lhzomdzhfje5gf3jlnkw.png",
      previewVideo: "https://www.w3schools.com/html/mov_bbb.mp4",
      height: 1280,
      width: 720,
      style: 'RENDER_3D',
      styleUUID: 'debdf72a-91a4-467b-bf61-cc02bdeb69c6',
      music: "None",
      musicDuration: "None",
      storyPrompt: `I want you to write a short inspirational 600 character story about a boy based on an analogy. The story should be straightforward and anecdotal with a conversational style. The sentences need to be short and direct, contributing to the story's easy-to-follow narrative. 
      Use boring words to write the story. Don't use hyperbolic language. 
      It's told in a matter-of-fact way, which makes the twist more surprising. Use dialogue effectively to reveal character motivations and to advance the plot. 
      The story should carry a philosophical message or moral. Ensure this is clearly conveyed, but allow the reader to uncover it themselves through the narrative and twist. 
      Always follow this framework:
      1. Scenario
      2. Buildup
      3. Clever plot
      4. Deep short conclusion
      Rules:
      1. The story should be 600 characters long. Never exceed this length. 
      2. Make sure the story (scenario, buildup, twist and conclusion) make sense and work well together. 
      3. The story needs to have a clever unexpected moral. 
      4. Make the story flowing. 
      5. Use an analogy.`,
      themeTemplate: `None`
    },
    {
      id: 11,
      title: "The cutest little animals",
      name: "The cutest little animals",
      description: "Delight in an enchanting collection of the tiniest, most adorable animals as they navigate mini adventures, spreading joy and wonder with their irresistible charm.",
      type: "Custom",
      image: "https://res.cloudinary.com/dyzcpcibf/image/upload/v1733077613/Template%20Card%20Images/ht1r80h4kklncyzxfj9r.png",
      previewVideo: "https://www.w3schools.com/html/mov_bbb.mp4",
      height: 1280,
      width: 720,
      style: 'RENDER_3D',
      styleUUID: 'debdf72a-91a4-467b-bf61-cc02bdeb69c6',
      music: "https://res.cloudinary.com/dyzcpcibf/video/upload/v1733078693/music/krvmg1adfbzawirecdgp.mp3",
      musicDuration: 19.099563,
      storyPrompt: `None`,
      themeTemplate: `Use this list of animal babies {white fluffy lamb,rooster, kitten, cow calf, white fluffy bird, wolf cub, white tiger cub, donkey foal, puppy } and create this same prompt for each animal babies from the list. Here is the prompt: a lush, highly detailed 3D render of a close-up, extreme macro shot of a tiny, {animal baby name here} sitting delicately on the tip of a human's finger, its soft, and endearing facial features meticulously modeled, with a joyful, excited expression, as if exploring its new perch, 
      illuminated by a dramatic ring light that accentuates the subtle textures and, set against a blurred, out-of-focus background in nature, with a vibrant, exaggerated color palette reminiscent of Pixar, rendered using state-of-the-art engines like Unreal Engine, Octane Engine, or Vray, to create a stunning, dreamlike atmosphere.

      IMPORTANT: Strictly format the response as [{ "prompt": "..." },{ "prompt": "..." },...] without any extra text or numbering and DO NOT CHANGE THE STRUCTURE OF THE RESPONSE.`
    },
    // {
    //   id: 12,
    //   title: "King Vikramaditya and the Mysterious Challenge",
    //   name: "King Vikramaditya and the Mysterious Challenge",
    //   description: "Dive into the captivating story of King Vikramaditya, a bold and muscular ruler, as he faces off against a towering, mystical figure adorned with sacred markings. This short delivers suspense, drama, and emotional depth, weaving vivid descriptions, engaging dialogue, and a thrilling climax into an unforgettable narrative. Perfect for creating an impactful and visually rich short",
    //   type: "Custom",
    //   image: "https://res.cloudinary.com/dyzcpcibf/image/upload/v1733077613/Template%20Card%20Images/ht1r80h4kklncyzxfj9r.png",
    //   previewVideo: "https://www.w3schools.com/html/mov_bbb.mp4",
    //   height: 1280,
    //   width: 720,
    //   style: 'RENDER_3D',
    //   styleUUID: 'debdf72a-91a4-467b-bf61-cc02bdeb69c6',
    //   music: "None",
    //   musicDuration: "None",
    //   storyPrompt: `I need you to write a story with a clear title that reflects the theme or key points of the narrative. The story should be approximately 140 words and should follow the structured outline of having a strong beginning, middle, and end, leading to a powerful climax and satisfying resolution. 
    //   Ensure that the story flows naturally through these sections and is engaging, with emotional highs and lows. Follow the style and tone mentioned earlier, whether it’s suspenseful, dramatic, comedic, or another tone, and keep it consistent throughout. Act as a creative storyteller, providing detailed descriptions, dialogue, and character development. 
    //   The storytelling should be vivid and emotionally engaging, guiding the narrative through a well-paced arc that keeps the audience hooked from start to finish. Language Option: Please write the story in Hindi. King: King Vikramaditya A muscular royal man, wearing traditional Indian attire with an orange turban, tattoos covering his muscular chest and arms, a thick beard, and a serious, determined expression. Mysterious man A towering mystical figure with long dreadlocks, a flowing beard, sacred face markings, adorned with spiritual trinkets, and wearing a red turban.`,
    //   themeTemplate: `None`
    // },
  ];

  const [templates] = useState(hardcodedTemplates);
  const [selectedTemplate, setSelectedTemplate] = useState(null);
  const [showPreview, setShowPreview] = useState(false);
  const [showCustomForm, setShowCustomForm] = useState(false);
  const [showLoginPopup, setShowLoginPopup] = useState(false);
  const [isGenerating, setIsGenerating] = useState(false);
  const [showGeneratedVideo, setShowGeneratedVideo] = useState(false);
  const [generatedVideoUrl, setGeneratedVideoUrl] = useState(null);
  const [generationError, setGenerationError] = useState(null);
  const [activeTab, setActiveTab] = useState("/templates");
  const { user } = useContext(AuthContext);
  const userEmail = user?.email;
  const userUID = user?.uid;
  const navigate = useNavigate();
  const handleNavigation = (path) => {
    setActiveTab(path);
    navigate(path);
  };

  const handlePreview = (template) => {
    setSelectedTemplate(template);
    setShowPreview(true);
  };

  const handleGenerate = async (template) => {
    if (!user) {
      setShowLoginPopup(true);
      return;
    }

    setIsGenerating(true);
    setGenerationError(null);

    try {
      const endpoint = template.storyPrompt === 'None' 
        ? `${serverbaseURL}create_template_video_cloudinary`
        : `${serverbaseURL}create_template_video_cloudinary_two`;

      const response = await axios.post(endpoint, {
        id: template.id,
        themeTemplate: template.themeTemplate,
        topic: template.title,
        email: userEmail,
        uid: userUID,
        height: template.height,
        width: template.width,
        music: template.music,
        musicDuration: template.musicDuration,
        storyPrompt: template.storyPrompt,
        style: template.style,
        styleUUID: template.styleUUID
      });

      if (response.data.videoUrl) {
        setGeneratedVideoUrl(response.data.videoUrl);
        setShowGeneratedVideo(true);
      } else {
        throw new Error('No video URL received');
      }
    } catch (error) {
      console.error('Video generation failed:', error);
      setGenerationError(
        error.response?.data?.error ||
        'Failed to generate video. Please try again.'
      );
    } finally {
      setIsGenerating(false);
    }
  };

  const handleCustomFormSubmit = (e) => {
    e.preventDefault();
    setShowCustomForm(false);
  };

  const handleRedirectToLogin = () => {
    setShowLoginPopup(false);
    navigate("/Login", { state: { from: { pathname: "/Templates" } } });
  };

  return (
    <div className="p-8">


      <div className="flex justify-center items-center pt-5 ">
        <div className="flex space-x-8 items-center justify-center ">
          {/* Templates Button */}
          <motion.button
            onClick={() => handleNavigation("/templates")}
            className={`px-8 py-3 rounded-full font-semibold text-lg shadow-md transition-all duration-300 ${activeTab === "/templates"
              ? "bg-gradient-to-r from-[#805af5] to-blue-600 text-white shadow-lg"
              : "bg-gray-200 text-gray-600 hover:bg-gradient-to-r hover:from-[#6eaffb] hover:to-[#4d8bfd] hover:text-white hover:shadow-lg"
              }`}
            whileHover={{ scale: 1.1 }}
            whileTap={{ scale: 0.95 }}
          >
            Templates
          </motion.button>

          {/* My Generations Button */}
          <motion.button
            onClick={() => handleNavigation("/my-generations")}
            className={`px-8 py-3 rounded-full font-semibold text-lg shadow-md transition-all duration-300 ${activeTab === "/my-generations"
              ? "bg-gradient-to-r from-[#805af5] to-blue-600 text-white shadow-lg"
              : "bg-[#efefff] text-gray-600 hover:bg-gradient-to-r from-[#805af5] to-blue-600  hover:text-white hover:shadow-lg"
              }`}
            whileHover={{ scale: 1.1 }}
            whileTap={{ scale: 0.95 }}
          >
            My Generations
          </motion.button>
        </div>

      </div>


      {/* Generation Progress Modal */}
      {isGenerating && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center transition-opacity duration-300 z-[100]">
          <div className="bg-white rounded-lg p-6 shadow-xl w-80 text-center">
            <Loader2 className="animate-spin h-8 w-8 mx-auto mb-4 text-primary" />
            <h2 className="text-xl font-semibold mb-2">Generating Your Video</h2>
            <p className="text-gray-600">This may take a few minutes...</p>
          </div>
        </div>
      )}

      {/* Generated Video Modal */}
      {showGeneratedVideo && generatedVideoUrl && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center transition-opacity duration-300 z-[100]">
          <div className="bg-white rounded-lg p-4 shadow-xl w-80">
            <h2 className="text-xl font-bold text-gray-800 mb-3">Generated Video</h2>
            <div className="relative w-full aspect-[9/16]">
              <video
                controls
                className="w-full h-full rounded-md shadow"
                autoPlay
              >
                <source src={generatedVideoUrl} type="video/mp4" />
                Your browser does not support the video tag.
              </video>
            </div>
            <button
              onClick={() => {
                setShowGeneratedVideo(false);
                setGeneratedVideoUrl(null);
              }}
              className="w-full mt-3 bg-primary text-white py-2 rounded-md font-semibold hover:bg-opacity-90 transition duration-200"
            >
              Close
            </button>
          </div>
        </div>
      )}

      {/* Error Modal */}
      {generationError && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center transition-opacity duration-300 z-[100]">
          <div className="bg-white rounded-lg p-4 shadow-xl w-80">
            <h2 className="text-xl font-bold text-red-600 mb-3">Generation Failed</h2>
            <p className="text-gray-800 mb-3">{generationError}</p>
            <button
              onClick={() => setGenerationError(null)}
              className="w-full bg-gray-300 text-gray-800 py-2 rounded-md font-semibold hover:bg-gray-400 transition duration-200"
            >
              Close
            </button>
          </div>
        </div>
      )}

      <h1 className="text-4xl font-bold text-center  m-10 font-custom text-black">Explore Our Templates</h1>

      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8 mx-auto max-w-6xl">
        {templates.map((template) => (
          <div
            key={template.id}
            className="bg-gradient-to-br from-[#f8f8ff] via-[#e6e6fa] to-[#dcdcdc] rounded-xl overflow-hidden shadow-lg transform transition duration-300 hover:scale-105 flex flex-col"
          >
            <img src={template.image} alt={template.name} className="w-full h-56 object-cover rounded-t" />

            <div className="p-6 flex-grow flex flex-col justify-between">
              <div className="flex-grow">
                <h2 className="text-xl font-custom font-semibold mb-2">{template.name}</h2>
                <p className="mb-4 text-sm text-[#55595c]">{template.description}</p>
              </div>
              <p className="text-md text-slate-900 font-semibold">Type: {template.type}</p>
            </div>

            <div className="flex justify-between p-4">
              < motion.button
                onClick={() => handlePreview(template)}

                className="bg-gradient-to-r from-[#805af5] to-[#cd99ff] text-white font-semibold w-full px-3 py-2 rounded-lg shadow-lg hover:bg-opacity-90 transition duration-200 mr-2  hover:bg-gradient-to-r hover:from-[#6a3ec2] hover:to-[#a68cfc]     text-center "
              >
                Preview
              </motion.button>
              <motion.button
                onClick={() => handleGenerate(template)}

                className="bg-gradient-to-r from-[#805af5] to-[#cd99ff] text-white font-semibold w-full px-3 py-2 rounded-lg shadow-lg hover:bg-opacity-90 transition duration-200 mr-2  hover:bg-gradient-to-r hover:from-[#6a3ec2] hover:to-[#a68cfc]  ml-2  text-center "
              >
                Generate
              </motion.button>

            </div>
          </div>
        ))}
      </div>

      {/* Preview Modal */}
      {showPreview && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center transition-opacity duration-300 z-[100]">
          <div className="bg-white rounded-lg p-4 shadow-xl w-80">
            <h2 className="text-xl font-bold text-gray-800 mb-3">{selectedTemplate.name} Preview</h2>
            <div className="relative w-full aspect-[9/16]">
              <video
                controls
                className="w-full h-full rounded-md shadow"
                autoPlay
              >
                <source src={selectedTemplate.previewVideo} type="video/mp4" />
                Your browser does not support the video tag.
              </video>
            </div>
            <button
              onClick={() => setShowPreview(false)}
              className="w-full mt-3 bg-gradient-to-r from-[#805af5] to-[#cd99ff] text-white py-2 rounded-md font-semibold hover:bg-opacity-90 transition duration-200"
            >
              Close
            </button>
          </div>
        </div>
      )}

      {/* Custom Form Modal */}
      {showCustomForm && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center transition-opacity duration-300 z-[100]">
          <div className="bg-white rounded-lg p-4 shadow-xl w-80">
            <h2 className="text-xl font-bold text-gray-800 mb-3">Request a Custom Template</h2>

            <form onSubmit={handleCustomFormSubmit} className="space-y-3">
              <input
                type="text"
                placeholder="Your Name"
                className="w-full p-2 rounded border border-gray-300 focus:outline-none focus:ring-2 focus:ring-primary"
                required
              />
              <input
                type="email"
                placeholder="Your Email"
                className="w-full p-2 rounded border border-gray-300 focus:outline-none focus:ring-2 focus:ring-primary"
                required
              />
              <textarea
                placeholder="Describe your custom template requirements"
                className="w-full p-2 rounded border border-gray-300 focus:outline-none focus:ring-2 focus:ring-primary"
                rows="4"
                required
              ></textarea>

              <button
                type="submit"
                className="w-full bg-primary text-white font-semibold py-2 rounded hover:bg-opacity-90 transition duration-200"
              >
                Submit Request
              </button>
            </form>

            <button
              onClick={() => setShowCustomForm(false)}
              className="w-full mt-3 bg-gray-300 text-gray-800 py-2 rounded hover:bg-gray-400 transition duration-200"
            >
              Cancel
            </button>
          </div>
        </div>
      )}

      {/* Login Popup */}
      {showLoginPopup && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center transition-opacity duration-300 z-[100]">
          <div className="bg-white rounded-lg p-4 shadow-xl w-80">
            <h2 className="text-xl font-bold text-gray-800 mb-3">Login Required</h2>
            <p
              className="mb-3 text-blue-600 cursor-pointer hover:underline"
              onClick={handleRedirectToLogin}
            >
              Please log in to access this feature.
            </p>
            <button
              onClick={() => setShowLoginPopup(false)}
              className="w-full bg-gray-300 text-gray-800 py-2 rounded hover:bg-gray-400 transition duration-200"
            >
              Cancel
            </button>
          </div>
        </div>
      )}
    </div>
  );
}

export default Templates;
