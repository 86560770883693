import React from 'react'

const FAQ = ({question, answer}) => {
  return (
    <div>
    <h1 className="text-[20px] text-[#000] font-[500] font-custom break-words mt-8 mb-2">{question}</h1>
    <p className="text-[#55595c] font-[400] text-[14px] leading-relaxed">{answer}</p>
      
    </div>
  )
}

export default FAQ