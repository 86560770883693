import React, { useContext, useState, useEffect } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { ArrowLeft } from "lucide-react";
import { AuthContext } from "../provider/AuthProvider";
import axios from "axios";
import Modal from "react-modal"; // Install this if using react-modal
import { motion } from "framer-motion";
import { serverbaseURL } from "../constant/index.jsx";

const ViewGenerations = () => {
  const { user } = useContext(AuthContext);
  const location = useLocation();
  const navigate = useNavigate();

  const [videoItem, setVideoItem] = useState(null);
  const [generations, setGenerations] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedVideoUrl, setSelectedVideoUrl] = useState(null);
  const [error, setError] = useState(null);

  useEffect(() => {
    if (location.state && location.state.item) {
      setVideoItem(location.state.item);

      const fetchSeriesData = async () => {
        try {
          const response = await axios.get(
            `${serverbaseURL}get_template_videos/${location.state.item._id}`
          );
  
          // Extracting `data` from the response
          let vidGens = response?.data?.data;
  
          if (Array.isArray(vidGens)) {
            console.log("Valid array of generations:", vidGens.length);
            setGenerations(vidGens);
          } else if (vidGens) {
            console.log("Single object received:", vidGens);
            setGenerations([vidGens]); // Wrap in an array for consistent rendering
          } else {
            console.error("Unexpected data format:", response?.data);
            setGenerations([]);
          }
        } catch (error) {
          console.error("Error fetching generations:", error);
          setError("Failed to fetch generations. Please try again later.");
        }
      };

      fetchSeriesData();
    } else {
      navigate("/");
    }
  }, [location, navigate, user]);

  const openModal = (videoUrl) => {
    setSelectedVideoUrl(videoUrl);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setSelectedVideoUrl(null);
  };

  const renderAdvancedForm = () => {
    if (error) {
      return (
        <div className="p-8 w-full rounded-lg bg-red-100 text-red-700">
          <p>{error}</p>
        </div>
      );
    }
console.log(generations?.length );
    return generations?.length > 0 ? (
      <div className="overflow-x-auto bg-[#efefff] rounded-lg shadow-lg">
        <table className="min-w-full bg-white border-gray-400 rounded-lg shadow-md overflow-hidden">
          <thead>
            <tr className="bg-gradient-to-r from-indigo-600 to-purple-600 text-white text-xs sm:text-sm uppercase tracking-wide">
              <th className="py-3 px-4 sm:px-6 text-left font-semibold">
                Generated Date
              </th>
              <th className="py-3 px-4 sm:px-6 text-left font-semibold">
                Channel Name
              </th>
              <th className="py-3 px-4 sm:px-6 text-left font-semibold">
                Video Link
              </th>
            </tr>
          </thead>
          <tbody className="divide-y bg-[#f8f8ff]">
            {generations?.map((item) => (
              <tr key={item._id}
                className="hover:bg-gray-100 transition-all duration-150"
              >
                <td className="py-3 px-4 sm:px-6 text-gray-700 text-xs sm:text-sm text-left whitespace-nowrap align-middle">
                  {new Date(item.createdOn).toLocaleDateString()}
                </td>
                <td className="py-3 px-4 sm:px-6 text-gray-700 text-xs sm:text-sm text-left whitespace-nowrap align-middle">
                  {item.topic}
                </td>
                <td className="py-3 px-4 sm:px-6 text-indigo-600 text-xs sm:text-sm text-left align-middle">
                  <button
                    onClick={() => openModal(item.videoUrl)}
                    className="text-indigo-500 hover:text-indigo-700 underline transition duration-200"
                  >
                    View Video
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    ) : (
      <motion.div className="p-8 w-full md:px-16 shadow-xl rounded-lg bg-[#805af5]"
        initial={{ opacity: 0, x: -1000, scale: 0.95 }}
        animate={{ opacity: 1, x: 0, scale: 1 }}
        transition={{
          duration: 0.4,
          ease: "easeInOut",
          delay: 0.1,
        }}
      >
        <p className="text-white text-lg   font-bold pb-8">
          You haven't started a Faceless Video channel yet.
        </p>

        <Link to="/dashboard/create">
          <button className=" text-white py-3 px-6 text-lg rounded-lg font-semibold mb-5"
          >
            CREATE YOUR CHANNEL
          </button>
        </Link>
      </motion.div>
    );
  };

  if (!videoItem) {
    return <div>Loading...</div>;
  }

  return (
    <div className="bg-white min-h-screen px-6 ">
      <div className=" pt-6 mb-6">
        <Link
          to="/dashboard"
          className="flex items-center pt-7 text-indigo-600 hover:text-indigo-700 transition duration-300 ease-in-out"
        >
          <ArrowLeft className="mr-2" size={20} />
          <span className="text-lg font-bold">Back to Dashboard</span>
        </Link>
      </div>
      <div className="bg-[#efefff] p-8 rounded-lg shadow-sm">
        {renderAdvancedForm()}
      </div>

      {/* Modal */}
      <Modal
        isOpen={isModalOpen}
        onRequestClose={closeModal}
        contentLabel="Video Modal"
        className="modal"
        overlayClassName="modal-overlay"
      >
        <div className="modal-content">
          <button onClick={closeModal} className="close-button">Close</button>
          {selectedVideoUrl && (
            <iframe
              width="100%"
              height="400px"
              src={`${selectedVideoUrl}?autoplay=1`}
              title="Video"
              frameBorder="0"
              allow="autoplay; fullscreen"
              allowFullScreen
            ></iframe>
          )}
        </div>
      </Modal>
    </div>
  );
};

export default ViewGenerations;