import { useContext, useState, useEffect } from "react";
import GradientHeading from "../components/GradientHeading";
import FAQ from "../components/FAQ";
import { AuthContext } from "../provider/AuthProvider";
import axios from "axios";
import { serverbaseURL } from "../constant/index";
import { RiLinkM, RiMailLine, RiBarChartLine } from 'react-icons/ri';
import { motion } from "framer-motion";
import debounce from "lodash.debounce";




const AffiliateCreate = () => {
  const [paypalEmail, setPaypalEmail] = useState("");
  const { user } = useContext(AuthContext);
  const [referralCode, setReferralCode] = useState("");
  const [referralLink, setReferralLink] = useState("");
  const [loading, setLoading] = useState(false);
  const [updatedEmail, setUpdatedEmail] = useState("");
  const [baseUrl] = useState("https://www.automoviecreator.com?ref=");
  const userId = user?.uid;


  // Dialog state
  const [dialog, setDialog] = useState({
    open: false,
    message: "",
    onClose: () => { },
  });

  const openDialog = (message, onClose = () => { }) => {
    setDialog({ open: true, message, onClose });
  };

  const closeDialog = () => {
    if (dialog.onClose) dialog.onClose();
    setDialog({ open: false, message: "", onClose: () => { } });
  };


  // Fetch referral code on component mount
  const fetchReferralCode = async () => {
    if (!userId) return; // Ensure userId is present
    try {
      const response = await axios.get(`${serverbaseURL}get-referral-code/${userId}`);
      setReferralLink(response.data.referralLink || "");
      // Store the updated email in state
      setUpdatedEmail(response.data.updatedEmail || "");
    } catch (error) {
      console.error("Error fetching referral code:", error.response?.data?.message || error.message);
    }
  };

  useEffect(() => {
    if (userId) {
      fetchReferralCode(); // Fetch referral code when the component mounts or userId changes
    }
  }, [userId]);



  // Handle creation of affiliate referral link
  const handleCreateAffiliate = async () => {
    if (!userId) {
      openDialog("User is not logged in. Please log in to generate a referral link.");
      return;
    }
    if (!referralCode.trim()) {
      openDialog("Please provide a valid referral code.");
      return;
    }
    try {
      setLoading(true);
      const response = await axios.post(`${serverbaseURL}affiliate-create`, {
        userId,
        referralCode,
      });
      // Handle response statuses
      if (response.status === 200) {
        openDialog(response.data.message); // Referral link created successfully
        await fetchReferralCode(); // Fetch the updated referral link
      } else if (response.status === 400) {
        openDialog("Referral link already exists. Please use your existing link.");
      } else {
        openDialog("Unexpected response from the server. Please try again.");
      }
    } catch (error) {
      // Handle backend errors or other errors
      if (error.response) {
        const status = error.response.status;
        const message = error.response.data?.message || "An error occurred";

        if (status === 404) {
          openDialog("User not found. Please ensure you are logged in.");
        } else if (status === 400) {
          openDialog("Referral link already exists. Please use your existing link.");
        } else if (status === 500) {
          openDialog("Internal server error. Please try again later.");
        } else {
          openDialog(`Error: ${message}`);
        }
      } else {
        openDialog("Network error or server is unreachable. Please try again.");
      }
    } finally {
      setLoading(false);
    }
  };


  // Handle input change with debounce for smoother UX
  const handleChange = debounce((value) => {
    if (value.startsWith(baseUrl)) {
      setReferralCode(value);
    }
  }, 300);



  // Handle PayPal email update
  const handleUpdateEmail = async () => {
    if (!paypalEmail.trim()) {
      openDialog("Please enter a valid PayPal email.");
      return;
    }

    if (window.confirm("Are you sure you want to update your PayPal account email?")) {
      try {
        const response = await axios.put(`${serverbaseURL}update-payment-email`, {
          userId,
          email: paypalEmail,
        });
        const { message, updatedEmail } = response.data;



        // Show success message
        openDialog(`Success!\n\nMessage: ${message}\nUpdated PayPal Email: ${updatedEmail}`);
        await fetchReferralCode(); // Fetch the updated referral link
      } catch (error) {
        console.error("Error updating PayPal email:", error.response?.data?.message || error.message);
        openDialog("Error updating PayPal email. Please try again.");
      }
    }
  };

  return (
    <div>
      {referralLink ? (
        <div className="min-h-screen mt-16">

          <div className="bg-[#efefff] max-w-3xl mx-auto px-6 py-8 md:px-12 md:py-10 shadow-lg rounded-lg  mt-8 sm:mt-12">
            {/* Header */}
            <motion.h1
              initial={{ opacity: 0, y: -30 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.5 }}
              className=" pb-8 mb-5 text-center text-3xl font-bold font-custom text-black"
            >
              Welcome to our Affiliate Dashboard
            </motion.h1>

            <div className="space-y-12">
              {/* Affiliate Link Section */}
              <motion.div
                initial={{ opacity: 0, x: -50 }}
                animate={{ opacity: 1, x: 0 }}
                transition={{ duration: 0.6, delay: 0.2 }}
              >
                <h2 className="md:text-xl flex items-center  text-lg font-semibold text-[#55595c] mb-2">
                  <span className="mr-2 text-[#5a76f7]">
                    <RiLinkM size={24} />
                  </span>
                  Your Unique Affiliate Link
                </h2>
                <input
                  type="text"
                  name="affiliate"
                  className="w-full px-4 py-2 rounded-lg bg-[#f9f9f9] border border-[#d1d1d6] focus:border-[#4f75e1] focus:ring-[#4f75e1] focus:outline-none transition duration-200"
                  value={`${referralLink}`}
                  readOnly
                />
              </motion.div>

              {/* PayPal Email Section */}
              <motion.div
                initial={{ opacity: 0, x: 50 }}
                animate={{ opacity: 1, x: 0 }}
                transition={{ duration: 0.6, delay: 0.4 }}
              >
                <h2 className="md:text-xl flex items-center  text-lg font-semibold text-[#55595c] mb-2">
                  <span className="mr-2 text-[#5a76f7]">
                    <RiMailLine size={24} />
                  </span>
                  PayPal Account Email
                </h2>
                <div className="flex flex-col sm:flex-row gap-4 sm:gap-6">
                  <input
                    type="email"
                    name="paypal"
                    className="w-full px-4 py-2 rounded-lg bg-[#f9f9f9] border border-[#d1d1d6] focus:border-[#4f75e1] focus:ring-[#4f75e1] focus:outline-none transition duration-200"
                    placeholder="PayPal Account "
                    value={paypalEmail}
                    onChange={(e) => setPaypalEmail(e.target.value)}
                  />
                  <motion.button
                    whileHover={{ scale: 1.05 }}
                    whileTap={{ scale: 0.95 }}
                    className="bg-gradient-to-r from-[#805af5] to-blue-600 text-white py-2 px-6 rounded-md text-sm font-bold hover:shadow-lg transition duration-200"
                    onClick={handleUpdateEmail}
                  >
                    Update
                  </motion.button>
                </div>
                <p className="items-center  text-center text-md font-semibold text-[#55595c] mt-6">You will receive your commission here:</p>
                <p className="text-center text-md font-semibold text-gray-900 ">
                  <span className="text-[#55595c]"> PayPal Account : </span> {updatedEmail || "No paypal account updated yet."}
                </p>
              </motion.div>
            </div>





          </div>
          <div className=" mt-24  min-h-screen">
            <div className="transition-all duration-400 min-h-[450px] w-full max-w-[999px] h-auto py-[105px] px-16 sm:px-8 lg:px-16 mx-auto bg-white shadow-xl rounded-lg">
              <h1 className="text-[24px] text-[#000] font-[500] font-custom break-words mb-5">
                Frequently Asked Questions
              </h1>
              <div className="space-y-5">
                <FAQ
                  question="How does this work?"
                  answer="The way it works is simple! We give you a unique affiliate URL to share. If someone registers an account and purchases a membership within 30 days after clicking the link, we'll give you a cut of the sale!"
                />
                <FAQ
                  question="What commissions do I get?"
                  answer="Our affiliate program offers a 30% recurring commission. Our recurring model means great passive income for you."
                />
                <FAQ
                  question="When are payouts sent out?"
                  answer="Payouts will be sent on the 1st of every month through PayPal."
                />
                <FAQ
                  question="Is there a minimum payout requirement?"
                  answer="Yes. You need to have a minimum 'unpaid commissions' of $30 before receiving your payout in order to keep transaction fees low."
                />
                <FAQ
                  question="How does affiliate tracking work?"
                  answer="The way the AutoShorts.ai affiliate tracking works is if someone clicks on your link, a 30-day cookie is attached in their browser. This tracks them so when they register an account, they are locked-in to be attributed to you forever."
                />
                <FAQ
                  question="Can I run ads to promote AutoShorts.ai?"
                  answer="In general, you can. However, we DO NOT allow promotion through Google Search ads. You can run ads on other platforms such as Facebook, Instagram, TikTok, Reddit, YouTube, etc."
                />
              </div>
            </div>
          </div>
        </div>

      ) : (
        <div className="px-5 md:px-16 mt-24">
          <motion.div
            initial={{ opacity: 0, scale: 0.9 }}
            animate={{ opacity: 1, scale: 1 }}
            transition={{ duration: 0.5, ease: "easeOut" }}
            className="bg-[#efefff] max-w-2xl mx-auto  px-8 py-10 md:px-16 shadow-xl rounded-lg border"
          >
            <motion.h2
              initial={{ y: -30, opacity: 0 }}
              animate={{ y: 0, opacity: 1 }}
              transition={{ duration: 0.5, ease: "easeOut", delay: 0.2 }}
              className="  my-2 text-center text-3xl font-bold font-custom text-black"
            >
              Create Affiliate Link
            </motion.h2>
            <motion.p
              initial={{ y: -20, opacity: 0 }}
              animate={{ y: 0, opacity: 1 }}
              transition={{ duration: 0.5, ease: "easeOut", delay: 0.4 }}
              className="text-center font-semibold font-custom text-gray-900 pb-12"
            >
              Create a referral URL and share it with others.
            </motion.p>

            <motion.div
              initial={{ opacity: 0, x: -30 }}
              animate={{ opacity: 1, x: 0 }}
              transition={{ duration: 0.6, ease: "easeOut", delay: 0.6 }}
              className="flex flex-col w-full"
            >
              <label
                htmlFor="affiliate"
                className="block text-lg font-semibold  md:text-xl  text-[#55595c] mb-1"
              >
                Create your referral URL
              </label>
              <div className="flex items-center gap-2 w-full px-4 py-2 mb-2 rounded-lg bg-[#f9f9f9] border border-[#d1d1d6] focus-within:border-[#4f75e1] focus-within:ring-[#4f75e1] focus:outline-none transition duration-200">
                <RiLinkM size={24} className="text-black" />
                <input
                  type="text"
                  name="affiliate"
                  value={`${baseUrl}${referralCode.replace(baseUrl, "")}`}
                  onChange={(e) => handleChange(e.target.value)}
                  className="w-full bg-transparent outline-none text-[#333] text-base"
                  placeholder="Enter your referral code"
                />
              </div>
            </motion.div>

            <motion.div
              initial={{ opacity: 0, scale: 0.8 }}
              animate={{ opacity: 1, scale: 1 }}
              transition={{ duration: 0.5, ease: "easeOut", delay: 0.8 }}
              className="flex justify-center items-center mt-8"
            >
              <motion.button
                whileHover={{ scale: 1.05 }}
                whileTap={{ scale: 0.95 }}
                className="bg-gradient-to-r from-[#805af5] to-blue-600 text-white py-2 px-6 rounded-md text-lg font-semibold hover:shadow-lg transition duration-200"
                onClick={handleCreateAffiliate}
                disabled={loading}
              >
                {loading ? "Creating..." : "Create Affiliate Link"}
              </motion.button>
            </motion.div>
          </motion.div>
        </div>


      )}
      {dialog.open && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
          <div className="bg-white rounded-lg shadow-lg p-6 w-4/5 max-w-md text-center">
            <p className="text-gray-800 font-medium text-lg">{dialog.message}</p>
            <motion.button
              whileHover={{ scale: 1.05 }}
              whileTap={{ scale: 0.95 }}
              onClick={closeDialog}
              className="bg-gradient-to-r from-[#805af5] to-blue-600 text-white px-4 py-2 rounded-md shadow mt-4 transition duration-200"
            >
              OK
            </motion.button>
          </div>
        </div>
      )}
    </div>
  );
};

export default AffiliateCreate;
