import React, { useEffect, useState, useContext } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { serverbaseURL } from "../constant/index.jsx";
import { AuthContext } from "../provider/AuthProvider";
import { Calendar, Video, ExternalLink, Share2, Loader2 } from 'lucide-react';

const MyGenerations = () => {
  const [videos, setVideos] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const navigate = useNavigate();
  const { user } = useContext(AuthContext);
  const userEmail = user?.email
  const useruid = user?.uid;

  useEffect(() => {
    const fetchVideos = async () => {
      try {
        const response = await axios.post(`${serverbaseURL}template_videos`, {
          uid: useruid  // send uid in the body for POST request
        });

        if (response.data.success) {
          setVideos(response.data.data);
        }
      } catch (error) {
        console.error("Error fetching template videos:", error);
      } finally {
        setIsLoading(false);
      }
    };
    fetchVideos();
  }, []);

  const handleSchedulePost = (videoUrl, topic) => {
    navigate('/schedulePost', { state: { videoUrl, topic } });
  };

  if (isLoading) {
    return (
      <div className="min-h-screen  flex items-center justify-center">
        <div className="flex flex-col items-center gap-4">
          <Loader2 className="w-8 h-8 text-blue-500 animate-spin" />
          <p className="text-gray-600 font-medium">Loading your generations...</p>
        </div>
      </div>
    );
  }

  return (
    <div className="min-h-screen py-10 px-4">
      <div className="max-w-5xl mx-auto">
        <div className="flex items-center gap-3 mb-8">
          <Video className="w-8 h-8 text-gray-900" />
          <h2 className="text-3xl font-bold text-black">My Generations</h2>
        </div>

        {videos.length > 0 ? (
          <div className="grid gap-6">
            {videos.map((video) => (
              <div
                key={video._id}
                className="bg-[#efefff] rounded-lg shadow-lg p-6 transform hover:scale-[1.02] transition-all duration-300"
              >
                <div className="flex flex-col md:flex-row items-start md:items-center justify-between">
                  <div className="flex-1">
                    <h3 className="text-xl font-semibold text-gray-900 mb-3 line-clamp-1">
                      {video.topic}
                    </h3>
                    <div className="flex items-center gap-2 text-[#55595c] mb-4">
                      <Calendar className="w-4 h-4" />
                      <p className="text-sm">
                        Generated on: {new Date(video.createdOn).toLocaleDateString('en-US', {
                          year: 'numeric',
                          month: 'long',
                          day: 'numeric'
                        })}
                      </p>
                    </div>
                    <div className="flex flex-wrap gap-3">
                      <a
                        href={video.videoUrl}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="inline-flex items-center gap-2 bg-white text-blue-600 px-4 py-2 rounded-lg hover:bg-blue-100 transition-colors duration-200"
                      >
                        <ExternalLink className="w-4 h-4" />
                        Watch Video
                      </a>
                      <button
                        className="inline-flex items-center rounded-lg gap-2 bg-gradient-to-r from-[#805af5] to-blue-600 text-white px-4 py-2 rounded-lghover:shadow-lg transition duration-200"

                        onClick={() => handleSchedulePost(video.videoUrl, video.topic)}
                      >
                        <Share2 className="w-4 h-4" />
                        Schedule Post
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        ) : (
          <div className="text-center py-16 bg-[#efefff] rounded-xl shadow-lg">
            <Video className="w-16 h-16 text-black mx-auto mb-4" />
            <h3 className="text-2xl font-semibold text-gray-900 mb-2">No Videos Yet</h3>
            <p className="text-[#55595c] text-lg">
              Your generated videos will appear here once they're ready.
            </p>
          </div>
        )}
      </div>
    </div>
  );
};

export default MyGenerations;