import React from "react";

const PrivacyPolicy = () => {
  return (
    <div className="bg-white min-h-screen">
      <div className="transition-all duration-400 min-h-[450px] w-full max-w-[805px] h-auto py-[105px] px-4 mx-auto">
        <h1 className="text-[24px] text-[#000] font-[500] font-custom break-words mb-5">
          Privacy Policy
        </h1>

        <section className="mb-6">
          <h2 className="text-[20px] text-[#000] font-[500] font-custom break-words mt-8 mb-2">
            1. Introduction
          </h2>
          <p className="text-[#55595c] font-[400] text-[14px] leading-relaxed">
            At Automoviecreator.com, we are committed to protecting your
            privacy. This Privacy Policy outlines the types of information we
            collect, how it is used, stored, and shared, as well as your rights
            regarding your data.
          </p>
        </section>

        <section className="mb-6">
          <h2 className="text-[20px] text-[#000] font-[500] font-custom break-words mt-8 mb-2">
            2. Information We Collect
          </h2>
          <p className="text-[#55595c] font-[400] text-[14px] leading-relaxed">
            We collect both personal and non-personal information when you use
            our platform. Personal information includes details like your name,
            email address, payment information, and any other details you
            voluntarily provide. Non-personal information includes data on
            website usage, IP address, device information, and cookies, which
            help improve our services.
          </p>
        </section>

        <section className="mb-6">
          <h2 className="text-[20px] text-[#000] font-[500] font-custom break-words mt-8 mb-2">
            3. How We Use Your Information
          </h2>
          <p className="text-[#55595c] font-[400] text-[14px] leading-relaxed">
            We use collected information to provide, maintain, and improve our
            services, including processing payments, personalizing user
            experience, and communicating updates. We may also use aggregated,
            anonymized data for analytics to understand usage patterns and
            improve our platform's performance.
          </p>
        </section>

        <section className="mb-6">
          <h2 className="text-[20px] text-[#000] font-[500] font-custom break-words mt-8 mb-2">
            4. Sharing and Disclosure
          </h2>
          <p className="text-[#55595c] font-[400] text-[14px] leading-relaxed">
            Your information will not be sold or shared with third parties
            except in cases required by law or where necessary to provide
            services (e.g., payment processors, analytics providers).
            Third-party partners are bound by confidentiality agreements and
            may only use data as instructed by us.
          </p>
        </section>

        <section className="mb-6">
          <h2 className="text-[20px] text-[#000] font-[500] font-custom break-words mt-8 mb-2">
            5. Cookies and Tracking Technologies
          </h2>
          <p className="text-[#55595c] font-[400] text-[14px] leading-relaxed">
            We use cookies and similar technologies to track user activity and
            enhance your experience. Cookies help us remember your preferences,
            understand how you interact with the site, and track aggregated
            traffic data. You can manage cookies through your browser settings,
            but disabling them may affect site functionality.
          </p>
        </section>

        <section className="mb-6">
          <h2 className="text-[20px] text-[#000] font-[500] font-custom break-words mt-8 mb-2">
            6. Data Security
          </h2>
          <p className="text-[#55595c] font-[400] text-[14px] leading-relaxed">
            We employ industry-standard security measures to protect your
            information from unauthorized access, use, or disclosure. However,
            no method of transmission over the internet or electronic storage is
            entirely secure, so we cannot guarantee absolute security.
          </p>
        </section>

        <section className="mb-6">
          <h2 className="text-[20px] text-[#000] font-[500] font-custom break-words mt-8 mb-2">
            7. Data Retention
          </h2>
          <p className="text-[#55595c] font-[400] text-[14px] leading-relaxed">
            Your personal data will be retained only as long as necessary to
            fulfill the purposes outlined in this policy or as required by law.
            After this period, your data will be securely deleted or
            anonymized.
          </p>
        </section>

        <section className="mb-6">
          <h2 className="text-[20px] text-[#000] font-[500] font-custom break-words mt-8 mb-2">
            8. Your Rights
          </h2>
          <p className="text-[#55595c] font-[400] text-[14px] leading-relaxed">
            Depending on your location, you may have rights regarding your
            personal data, such as access, correction, deletion, and data
            portability. If you wish to exercise any of these rights, please
            contact us.
          </p>
        </section>

        <section className="mb-6">
          <h2 className="text-[20px] text-[#000] font-[500] font-custom break-words mt-8 mb-2">
            9. Changes to the Privacy Policy
          </h2>
          <p className="text-[#55595c] font-[400] text-[14px] leading-relaxed">
            We may update this Privacy Policy occasionally to reflect changes in
            our practices or legal requirements. Updates will be posted on this
            page with a revised effective date. Continued use of the site after
            changes are posted indicates acceptance of the revised policy.
          </p>
        </section>

        <section className="mb-6">
          <h2 className="text-[20px] text-[#000] font-[500] font-custom break-words mt-8 mb-2">
            10. Contact Us
          </h2>
          <p className="text-[#55595c] font-[400] text-[14px] leading-relaxed mb-4">
            If you have questions or concerns about this Privacy Policy, please
            contact us at info@automoviecreator.com.
          </p>
        </section>

        <p className="text-[#000] font-[500] text-[14px] leading-relaxed mb-8">
          By using Automoviecreator.com, you acknowledge that you have read,
          understood, and agreed to our Privacy Policy.
        </p>
      </div>
    </div>
  );
};

export default PrivacyPolicy;
